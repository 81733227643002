@import '../styles/variables.scss';

#homepage{
    #mobile-slc-btn{
        display: none;
        @media (max-width: 600px) {
            padding-bottom: 40px;
            display: block;
            padding-left: 0;
            img{
                display: block;
                margin: 0 auto;
            }
        }
    }
    .intro{
        padding: 40px 0px;
        h1{
            padding: 10px 0px;
            @media (max-width: 600px) {
                padding-top: 20px;
            }
        }
        .demo-img{
            display: block;
            width: 100%;
            max-width: 800px;
            margin: 0 auto;
            box-shadow: 0px 0px 15px 0px $blue;
            @media (max-width: 600px) {
                width: 90%;
            }
        }
    }

    .how-it-works{
        display: grid;
        grid-template-columns: 1fr 1fr;
        @media (max-width: 600px) {
            display:flex;
            flex-flow: column;
        }
        .how-to-img{
            width: 100%;
            max-width: 600px;
            float: left;
            padding: 30px;
            @media (max-width: 600px) {
                padding: 0px;
                order:2;
                width: 80%;
                margin: 0 auto;
                padding-top: 30px;
            }
        }
        .how-to{
            margin-top: 130px;
            color: $dark;
            padding-left: 150px;
            @media (max-width: 600px) {
                padding-left: 0px;
                order:1;
                margin-top: 0;
                text-align: center;
            }
            @media (min-width: 600px) and (max-width: 800px) {
                margin-top: 70px;
                padding-left: 70px;
            }
            h2{
                color: $darkOrange;
                font-size: 20px;
                padding: 10px;
                text-align: left;
                @media (max-width: 600px) {
                    text-align: center;
                }
            }
            .how-to-points{
                font-size: 16px;
                padding: 10px;
                span{
                    font-weight: 600;
                }
                
            }
        }
    }

    .promo{
        padding: 100px;
        @media (max-width: 600px) {
            padding: 50px;
        }
        .promo-grid{
            max-width: 1000px;
            margin: 0 auto;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            @media (max-width: 600px) {
                grid-template-columns: 1fr;
            }
            .promo-img{
                width: 90%;
                max-height: 100%;
                margin: 0 auto;
                display: block;
                @media (max-width: 600px) {
                    width: 80%;
                    background: #ffffff40;
                }
            }
            p{
                color: $orange;
                text-align: center;
                font-size: 18px;
            }
        }
    }
    
}