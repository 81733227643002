@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;700;900&display=swap');
@import '../styles/variables.scss';

body{
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    background: #fff url("data:image/svg+xml,%3Csvg width='615' height='554' xmlns='http://www.w3.org/2000/svg'%3E %3Cg fill='none' fill-rule='evenodd'%3E %3Cpath d='M463.283 528.726c45.144 0 95.716 8.237 151.717 24.712V0H41.584C28.58 76.038-4.694 126.702.957 203.448c16.342 221.959 215.179 325.278 462.326 325.278z' fill='%23ebf3fa'/%3E %3Cpath d='M382.319 485.092c54.57 14.534 187.124 6.139 232.681-13.977V0H122.36c-16.232 41.585 18.088 107.04 18.088 154.372 0 203.855 45.087 278.309 241.87 330.72z' fill='%23d2e0ff'/%3E %3Cpath d='M443.883 440.71c54.208 0 128.702-54.567 171.117-81.896V0H231.198c-21.166 38.7-14.497 103.867-14.497 151.086 0 150.221 76.96 289.624 227.182 289.624z' fill='%23aec3ff'/%3E %3C/g%3E %3C/svg%3E") top right no-repeat fixed;
    margin: 0;
    padding: 0;
}

h1{
    color: $dark;
    margin: 0 auto;
    text-align: center;
    font-size: 30px;
    margin-block-start: 0;
    margin-block-end: 0;
}

h2{
    text-align: center;
    font-size: 24px;
    color: $dark;
    margin-block-start: 0;
    margin-block-end: 0;
}

p.subtitle{
    text-align: center;
    font-size: 16px;
    color: $dark;
    padding-top: 10px;
    padding-bottom: 30px;
    max-width: 600px;
    margin: 0 auto;
    line-height: 24px;
    @media (max-width: 600px) {
        max-width: 90%;
    }
}

.content{
    max-width: 1200px;
    margin: 0 auto;
}
.slc-btn{
    padding-top: 5px;
    padding-left: 20px;
}