#privacy-policy, #terms{
  padding: 50px;
  @media (max-width: 600px) {
    padding: 20px;
  }
  h1{
      padding-bottom: 30px;
      @media (max-width: 600px) {
        padding-top: 50px;
      }
  }  
  h2{
    font-size: 20px;
  }
}