@import '../styles/variables.scss';

#faqs{
    padding: 50px;
    background: $lightBlue;
    margin-bottom: 100px;
    @media (max-width: 600px) {
        padding: 30px  20px 80px 20px;
        margin-bottom: 0px;

    }
    #faq-box{
        max-width: 900px;
        margin: 0 auto;
        .q-a{
            border-bottom: 2px solid $lightBlue;
            padding: 10px 0 ;
            &:not(:last-child){
                border-bottom: 2px solid rgba(214, 214, 220, 0.582);
            }
            .question{
                pointer-events: none;
                color: $dark;
                padding: 5px 0;
                font-size: 16px;
                font-weight: 400;
            }
            .answer{
                padding: 10px 0 ;
                color: $darkBlue;
            }
        }
    }
}