@import '../styles/variables.scss';

#footer{
    background: $darkBlue;
    .content{
        max-width: 1200px;
        margin: 0 auto;
        color: $lightBlue;
        padding: 20px;
        #email{
            float: right;
            @media (max-width: 600px) {
                float: none;
                padding: 10px;
            }
        }
        .links{
            display: inline-block;
            a{
                display: block;
                color: $lightBlue;
                text-decoration: none;
                padding: 5px;
                @media (max-width: 600px) {
                    padding: 10px;
                }
                &:active, &:visited{
                    color: $lightBlue;
                }
                &:hover{
                    color: $orange;
                }
            }
        }
        
    }
}