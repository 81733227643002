@import '../styles/variables.scss';

#features{
    padding: 50px;
    background: $lightBlue;
    margin: 50px 0;
    @media (max-width: 600px) {
        padding: 30px  20px 80px 20px;
        margin-top: 50px;
        margin-bottom: 10px;

    }
    #features-box{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .feature{
            padding: 5px;
            color: $darkBlue;
            font-size: 18px;
            width: 33%;
            @media (max-width: 600px) {
                width: 50%;
            }
            box-sizing: border-box;
            .feature-img{
                display: block;
                width: 70%;
                margin: 0 auto;
            }
            p{
                text-align: center;
            }
        }
    }
}