@import "../styles/variables.scss";

#pricing {
  background: url("../images/background-min.svg") no-repeat;
  background-size: 80%;
  background-position: center bottom;
  @media (max-width: 600px) {
    padding-top: 50px;
  }
  .subtitle{
    @media (max-width: 600px) {
      display: none;
    }
  }
  h2{
    @media (max-width: 600px) {
      padding-bottom: 40px;
    }
  }
  .price-card {
    background: linear-gradient(
      135deg,
      rgba(163, 168, 240, 1) 0%,
      $darkBlue 100%
    );
    color: #fff;
    text-align: center;
    z-index: 1;
    width: 400px;
    margin: 0 auto;
    border-radius: 1rem;
    @media (max-width: 600px) {
      width: 90%;
    }
    .price-title {
      padding: 40px;
      font-size: 20px;
    }
    .price {
      font-size: 50px;
      padding-bottom: 2rem;
      color: white;
    }
    .price-small {
      font-size: 16px;
      color: hsla(240, 8%, 85%, 0.582);
    }
    .plan-pros {
      padding: 15px 0;
      font-size: 16px;
      text-align: left;
      padding-left: 30px;
      @media (max-width: 600px) {
        padding-left: 5px;
      }
    }
    .bottom-bar {
      border-bottom: 2px solid hsla(240, 8%, 85%, 0.582);
      margin: 0 80px;
      @media (max-width: 600px) {
        margin: 0 60px;
      }
    }

    .tick-mark {
      position: relative;
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 15px;
    }

    .tick-mark::before {
      position: absolute;
      left: 0;
      top: 50%;
      height: 50%;
      width: 2px;
      background-color: $orange;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }

    .tick-mark::after {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 2px;
      width: 100%;
      background-color: $orange;
      content: "";
      transform: translateX(10px) rotate(-45deg);
      transform-origin: left bottom;
    }

    .cta-btns {
      padding: 50px;
      .btn {
        margin: 0 auto;
        height: 2.6rem;
        width: 13.3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        outline: none;
        border: 0;
        font-weight: bold;
        background: #fff;
        color: $darkBlue;
        &:hover {
          color: white;
          background-color: $orange;
        }
        &:active {
          color: $darkBlue;
          background-color: $darkOrange;
        }
      }
    }
  }
}
